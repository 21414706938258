// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

.app {
  background-color: rgb(0, 162, 255);
  min-height: 100dvh;
  max-width: 100vw;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: repeat(24, 1fr);
  grid-auto-flow: column;
  grid-gap: 10px;
}
.app > * {
  grid-column: span 24;
}

.main > *:nth-child(odd) {
  background-color: rgba(128, 128, 128, 0.3);
}`, "",{"version":3,"sources":["webpack://./src/styles/reset.scss","webpack://./src/styles/main.scss","webpack://./src/styles/colors.scss","webpack://./src/styles/mixins.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,UAAA;ACCF;;ADEA;EAAG,sBAAA;ACEH;;AAHA;EACE,kCCLc;EDMd,kBAAA;EACA,gBAAA;EACA,gBAAA;EACA,aAAA;EACA,iCAAA;EACA,sCAAA;EACA,sBAAA;EACA,cAAA;AAMF;AAJE;EEdA,oBAAA;AFqBF;;AAAI;EACE,0CAAA;AAGN","sourcesContent":["html, body {\r\n  margin: 0;\r\n  padding: 0;\r\n}\r\n\r\n* {box-sizing: border-box;}","@import './reset.scss';\r\n@import './colors.scss';\r\n@import './mixins.scss';\r\n\r\n.app {\r\n  background-color: $primary-color;\r\n  min-height: 100dvh;\r\n  max-width: 100vw;\r\n  overflow: hidden;\r\n  display: grid;\r\n  grid-template-rows: auto 1fr auto;\r\n  grid-template-columns: repeat(24, 1fr);\r\n  grid-auto-flow: column;\r\n  grid-gap: 10px;\r\n\r\n  & > * {\r\n    @include width(24);\r\n  }\r\n}\r\n\r\n.main {\r\n  & > * {\r\n    &:nth-child(odd) {\r\n      background-color: rgba($sec-color, 0.3);\r\n    }\r\n  }\r\n}\r\n","$primary-color: rgb(0, 162, 255);\r\n$sec-color: gray;\r\n","@mixin width($columns: 24) {\r\n  grid-column: span $columns;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
