var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<html lang=\"en\">\r\n  <head>\r\n    <meta charset=\"UTF-8\" />\r\n    <title>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"htmlWebpackPlugin") : depth0)) != null ? lookupProperty(stack1,"options") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</title>\r\n    <meta name=\"viewport\" content=\"width=device-width, initial-scale=1\" />\r\n  </head>\r\n  <body>\r\n    <div class=\"app\">\r\n      <div id=\"HEADER\"></div>\r\n      <main class=\"main\">\r\n        <h1>HTML Page</h1>\r\n      </main>\r\n      <div id=\"FOOTER\">Footer</div>\r\n    </div>\r\n  </body>\r\n</html>";
},"useData":true});