var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<header class=\"header\">\r\n  <div class=\"header__logo\">\r\n    <img src="
    + alias4(((helper = (helper = lookupProperty(helpers,"logo") || (depth0 != null ? lookupProperty(depth0,"logo") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"logo","hash":{},"data":data,"loc":{"start":{"line":3,"column":13},"end":{"line":3,"column":21}}}) : helper)))
    + " alt=\"logo\" class=\"header__logo-img\"/>\r\n    <h2>"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":17}}}) : helper)))
    + "</h2>\r\n  </div>\r\n  <nav class=\"header__nav\">\r\n    <ul class=\"header__nav-list\">\r\n      <li class=\"header__nav-item\"><a href=\"/\">Home</a></li>\r\n      <li class=\"header__nav-item\"><a href=\"/html.html\">Html</a></li>\r\n      <li class=\"header__nav-item\"><a href=\"/css.html\">Css</a></li>\r\n      <li class=\"header__nav-item\"><a href=\"/javascript.html\">Javascript</a></li>\r\n      <li class=\"header__nav-item\"><a href=\"/about.html\">About</a></li>\r\n    </ul>\r\n  </nav>\r\n</header>";
},"useData":true});