// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.w-full {
  grid-column: span 24;
}

.header {
  display: flex;
  justify-content: space-between;
  background-color: rgb(0, 162, 255);
}
.header__logo {
  display: flex;
  align-items: center;
}
.header__logo-img {
  width: 40px;
  margin-right: 10px;
}
.header__nav-list {
  display: flex;
  list-style-type: none;
  gap: 20px;
}`, "",{"version":3,"sources":["webpack://./src/styles/grid.scss","webpack://./src/styles/mixins.scss","webpack://./src/components/header/header.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAEA;ECDE,oBAAA;ACCF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,kCCPc;ADQhB;AACE;EACE,aAAA;EACA,mBAAA;AACJ;AAEE;EACE,WAAA;EACA,kBAAA;AAAJ;AAGE;EACE,aAAA;EACA,qBAAA;EACA,SAAA;AADJ","sourcesContent":["@import './mixins.scss';\r\n\r\n.w-full {\r\n  @include width(24);\r\n}","@mixin width($columns: 24) {\r\n  grid-column: span $columns;\r\n}","@import '../../styles/colors.scss';\r\n@import '../../styles/mixins.scss';\r\n@import '../../styles/grid.scss';\r\n\r\n.header {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  background-color: $primary-color;\r\n\r\n  &__logo {\r\n    display: flex;\r\n    align-items: center;\r\n  }\r\n\r\n  &__logo-img {\r\n    width: 40px;\r\n    margin-right: 10px;\r\n  }\r\n  \r\n  &__nav-list {\r\n    display: flex;\r\n    list-style-type: none;\r\n    gap: 20px;\r\n  }\r\n\r\n  &__nav-list-item {\r\n  }\r\n}","$primary-color: rgb(0, 162, 255);\r\n$sec-color: gray;\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
